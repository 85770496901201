.h-full {
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  .h-full {
    height: 100vh;
    height: -webkit-fill-available;
  }
}
